.tl_popup_theme8_text
{
 font-size:30px;
 position: absolute;
 padding-top: 20px;
 padding-left: 50px;
}
.tl_popup_theme8_card{
    /*background-color: #fff;*/
    background-color: rgba(248,248,248,1);
    /* background-color: yellow; */
    width: 500px;
    height: 400px;
    z-index: 1;
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;

     margin-top: -200px;
    margin-left: -250px;
}

.close{
  color:#000000;
  padding: 9px !important;
}

.sub_h2{
    text-align: center;
    color: #7E2E3C;
    font-weight: 400;
}

.input1{
  width: 60% !important;
    margin-left: 20%;
     margin-top: 10%;
}

.input2{
  width: 60% !important;
    margin-left: 20%;
     margin-top: 1%;
}
.sub-btn{
      margin-top: 3%;
    margin-left: 40%;
    background-color: #7E2E3C;
    color:#fff;
}
.sub_h4{
         color: #888888;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
        font-family: 'Open Sans', arial;
}

.sub_footer{
    color: black;
    text-align: center;
    font-size: 13px;
    font-family: 'Open Sans', arial;
}

.apply_button{
  float:right; 
  background-color: #7E2E3C; 
  color: #fff;
}

#error-div{
  color: red;
  text-align: center;
}
#success-div{
  color: green;
  text-align: center;
}

.error_message{
  color: red;
  text-align: center;
}

.success_message{
  color: green;
  text-align: center;
}