@media only screen{

	.adblock-google{
		text-align: center;
		margin:auto;
		margin-top:5px;
		width: 100%;
		height:auto;
	}

	
}

