.apply-form input.form-control{
    background-color: #FFF;
}
.apply-form select.form-control{
    background-color: #FFF;
}
.apply-form textarea.form-control{
    background-color: #FFF;
}



.subscribe-job
{
    padding:5px; 
}

.subscribe-job .subscribe-title{
    color:#FFF;
}

.subscribe-job .btn{
    outline: none;
}

.subscribe-job input.form-control{
    background-color: #FFF;
}

.subscribe-job.subscribe-green{
    background-color: #31C3E7;
}

.subscribe-green .btn{
    background-color: #8CC739;
    border-color: #8CC739;
    color: #FFF;
}


.subscribe-job.subscribe-primary{
    background-color: #691a40;
}

.subscribe-primary .btn{
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #2c3e50;
}

.subscribe-job.subscribe-orange{
    background-color: #ec5b1d;
}

.subscribe-orange .btn{
    background-color: #454040;
    border-color: #454040;
    color: #FFF;
}






.entries-restaurant article {
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    padding-top: 10px;
    padding-bottom: 10px;
    border-width: 10px 1px 1px;
    border-style: solid dashed dashed;
    border-color: rgb(203, 32, 45);
    border-top: 10px solid rgb(203, 32, 45);
}


.google_login,.facebook_login{
    background-image: url('/images/social_buttons.png');
    background-repeat: none;
    width: 220px;
    height: 50px;
}

.google_login{
    background-position: 0px 50px;

    height: 45px;
}

.login-section{
    text-align: center;

}
.login-section div{
    margin: auto;
}
.contact_form{
    background-color: #faf8dc;
    box-shadow: 2px 2px 2px 2px #AAAAAA;
    padding: 10px 10px;
}

.contact_form h3{
    border:#000 0px none;
}


.contact_form input,.contact_form textarea{
    background-color: #FFF;
}

.has-error > input,.has-error > textarea{
    background-color: #fadced;
}

.event_search_input{
    width: 100%;
    padding: 4px;border-radius: 8px;
    height: 48px;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;
}
.event_submit_button{
    width: 100%;
    height: 48px;background-color: #f1c40f;font-size: 16px;
    font-weight: 500;
    line-height: 1.45;
    border-radius: 8px;
}

.event-card{
    transition: box-shadow .24s cubic-bezier(.4,0,.3,1),background-color .24s cubic-bezier(.4,0,.3,1),-webkit-box-shadow .24s cubic-bezier(.4,0,.3,1);
     padding-top: 12px;padding-bottom: 12px;
     padding-right:0px !important;
}
.event-section{
    border-radius: 4px;
    width: 100%;    display: block;    overflow: hidden;    box-shadow: 0 0 3px 0 rgba(0,0,0,.42);
    background: #fff; 
    /*height: 260px !important;*/
}
.event-content-image{
    position: relative;
    overflow: visible;
}
.event-detail{
    display: block;
    overflow: hidden;
    position: relative;
    padding: 20px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    padding-bottom: 0px;
    height: auto !important;
}

.event-media-content{
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;    flex-direction: row;
    -webkit-box-pack: start;justify-content: flex-start;
    -webkit-box-flex: 1;
    margin-top: -12px;
}
.event-media-content-extra-content{
    max-width: 48px;
    -webkit-box-flex: 0;padding-right: 16px;    margin: 0;
    padding: 0;
}
.event-date-thumbnail-month{
    color: #f05537;font-size: 22px;
    line-height: 2px;
}
.event-date-thumbnail-day{
    color: #6f7287; font-size:20px;padding-top: 5px;
}
.event-card-formatted-name{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;margin: 0;
    padding: 0;font-weight: 600 !important;
    margin-bottom: 0px !important; padding-left: 10px;
        margin-left: 12px !important;

}


@media (max-width:600px){
    .header-row .col-md-3{
        text-align: center;
    }
    .navbar-header{
        text-align: center;
    }
    .navbar-toggle{
        float: none;
        margin: auto;
        color: #FFF;
    }
    h1.page-title{

        font-size: 19px;
        line-height: 22px;
        font-weight: bold;

    }
    .entry-header{
        padding:10px;
    }
    #main{
        padding-top:0px;
    }
}

.btn-event-booking{
    background-color: #6e0a3c ; color: #ffffff;
}
.date-thumbnail__month{
    color:  #B22222;
}

.event-col-8{
    background-color: ##f6f1fb;
}
.event-text-display{
    font-weight: 400;
}
.event-subtitle{
    font-size: 15px;
}
.event-search-box{
    max-width: 100%;
}
.event-search-div{
    height: 100%;margin-left: auto;
}

.event-cat-div{
    padding-left: 12px;
    padding-top: 0px !important;
    margin-top: 10px;background-color: #6e0a3c;
}

.event-category{
    padding-bottom: 20px;
}
.event-image-placeholder{
    background-color:#0c1113;
}
.event-content-principal{
    margin-top: -20px;
    margin-left: 14px;
}
.cat-event{
    font-size: 19px; 
    color: #fff; 
    font-weight: 200;
    line-height: 17px;
}

.desc-article{
    width: 30rem; 
    padding: 5px;
     width: 100%;
     height:380px;
     overflow:hidden;
     display:inline-block;
    text-overflow: ellipsis;
    box-shadow: 0 2px 5px rgba(0,0,0,.25);

}
.article-card-img-top{
    height: 200px; width:100%; overflow:hidden;
}
.article-card-body{
    height: 130px !important;
}
.article-read-box{
    height: 50px !important;
}
.article-read-button{
    float: right; margin-right: 10px; margin-bottom :0px !important;
}
.article-cat-block{
    padding:5px; margin-top: 10px;background-color: #64183A;
}
.article-cat-title{
    margin:0px;padding:0px;font-size:110%;line-height:40px; color:white;
}
.desc-yoga{
    width: 30rem; padding: 5px; width: 100%; height:auto; 
    overflow:hidden;display:inline-block;text-overflow: ellipsis;box-shadow: 0 2px 5px rgba(0,0,0,.25);
}
.image-yoga{
    height:auto; width:100%;
    /*background-size:cover !important;*/
}
.yoga-card-body{
    height: 50px !important;
}
.yoga-read-button{
    float: right; margin-right: 10px ;margin-bottom :0px !important;
}
.yoga-read-block{
    height: 50px !important;
}
.business-search{
    border-width: 0px 1px 1px; border-style: dotted; 
    border-color: rgb(204, 204, 204); padding: 5px 12px; 
    z-index: 9999; position: absolute; width: 491px; display: none; 
    background-color: rgb(255, 255, 255);
}
.cat-business{
    background: #F3F3EF; padding: 20px 0px 20px 20px;
}
.desc-business{
    width: 30rem; padding: 5px; width: 100%; height:150px; 
    overflow:hidden;display:inline-block;text-overflow: ellipsis;box-shadow: 0 2px 5px rgba(0,0,0,.25); 
}
.desc-business-body{
    height: 150px; overflow:hidden;
}
.desc-business-div{
    background-color: #F1EEEF; height: 150px;
}
.business-title{
     margin-left: 15px; color: #5D1538;
}
.business-address{
    padding-top: 5px;margin-left: 15px;
}
.business-contact-info{
    height: 150px;background-color: #FAF8F9; 
}
.business-detail{
    padding-top: 5px; color: #5D1538 ;
}

.search_box_event{
    margin: auto !important;
    width: 62% !important;
    padding: 10px !important;
}
.eds-media-card-content_primary-content{
    overflow: hidden;height: 10vh;
    padding-bottom: 5px;
}
.eds-media-card-content_title{
    overflow: hidden;
    height: auto;
    padding-bottom: 5px;
}
.eds-is-hidden-accessible{
    height: 11vh;padding-top: 6px;
}
.event-image-content{
    height: auto !important;
}
.event-mar{
    margin-top: 9vh;    
    margin-left: -2vh;
}


