/**
 * see *.less for some documentation
 * some helpers located on libs.less
 */
.inline-block {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
}
.clearfix:after {
  clear: both;
}
/************************************************************************** 
 *
 * Set general styling for layout - for components styling see components.css
 * 
 * 1. Base rules
 * 2. Col XL
 * 3. Header
 * 4. Header Navbar
 * 5. Main
 * 6. Section
 * 7. Widget
 * 8. Footer 
 * 9. Responsive
 *
 * */
/**************************************************************************
 * 1. Base Rules
 */
html,
body {
  height: 100%;
}
body {
  font-family: 'Droid Sans', sans-serif;
  font-size: 14px;
  line-height: 24px;
}
.aligncenter {
  text-align: center;
}
.alignleft {
  text-align: left;
}
.alignright {
  text-align: right;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
}
img {
  max-width: 100%;
}
js img[data-src] {
  opacity: 0;
  transition: opacity 0.3s ease-in;
}
/**************************************************************************
 * 2. Col XL
 */
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.sm-pull-right,
.md-pull-right,
.lg-pull-right,
.xl-pull-right {
  float: none;
}
.colheight-sm-1,
.colheight-sm-2,
.colheight-sm-3 {
  height: 155px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
[class*="col-"].col-no-padding,
[class^="*col-"].col-no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
/**************************************************************************
 * 3. Header
 */
.navbar{
  margin-bottom:0px;
  background-color: #691A40;
}
.header-navbar {
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: 50px;
  height: 44px;
  line-height: 44px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  border-radius: 0px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  -o-background-clip: padding-box;
  background-clip: padding-box;
  border: none;
  min-height: inherit;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  font-family: 'Lato', sans-serif;
}
.header-navbar .header-navbar-inner {
  position: relative;
  padding: 0px;
  height: 44px;
}
.header-navbar .header-navbar-inner:after {
  bottom: -10px;
  right: -10px;
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #333;
}
.header-navbar .header-navbar-inner.search-toggled-in .nav.navbar-nav {
  display: none;
}
.header-navbar .header-navbar-inner.search-toggled-in .nav-icons .search-toggled-out-icon {
  display: none;
}
.header-navbar .header-navbar-inner.search-toggled-in .nav-icons .search-toggled-in-icon {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.header-navbar .header-navbar-inner.search-toggled-in .search-wrapper {
  display: block;
}
.header-navbar .search-wrapper {
  position: relative;
  overflow: hidden;
  display: none;
  height: 44px;
  line-height: 22px;
}
.header-navbar .search-wrapper .search-wrapper-inner {
  margin: 0 54px 0 10px;
  position: relative;
}
.header-navbar .search-wrapper .search-wrapper-inner form {
  padding: 0px;
  margin: 0px;
}
.header-navbar .search-wrapper .search-wrapper-inner input[type="text"] {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 0px;
  margin: 11px 0px 0px 0;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
.header-navbar .search-wrapper .search-wrapper-inner input[type="text"]:focus {
  outline: none;
}
.header-navbar .search-wrapper .search-wrapper-inner button {
  position: absolute;
  z-index: 2;
  right: -54px;
  top: 0px;
}
.header-navbar .btn-icon {
  padding: 0px;
  margin: 0px;
  line-height: 44px;
  height: 44px;
  width: 44px;
  border: none;
  background-color: transparent;
}
.header-navbar .navbar-brand {
  margin: 0px 5px 0px 0px;
  padding: 0px;
  width: 244px;
  height: 44px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  border-right: 1px solid transparent;
}
.header-navbar .navbar-brand a {
  position: relative;
  top: -30px;
  left: -15px;
  display: block;
  width: 244px;
  height: 56px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
}
.header-navbar .navbar-brand a:after {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  top: 56px;
  left: 0px;
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-bottom: 18px solid transparent;
  border-right: 15px solid #333333;
}
.header-navbar .navbar-brand a img {
  vertical-align: top;
  position: relative;
  height: auto;
}
body.scrolled .header-navbar {
  position: fixed;
  margin-top: 0px;
}
body.scrolled .header-navbar  .navbar-brand {
  width: 192px;
}
body.scrolled .header-navbar  .navbar-brand a {
  top: 0px;
  left: -5px;
  width: 192px;
  height: 44px;
  padding-right: 0px;
}
body.scrolled .header-navbar  .navbar-brand a:after {
  top: 44px;
  border-bottom-width: 0px;
}
body.scrolled .header-navbar  .nav-pages {
  display: none !important;
}
.header-navbar .nav-pages {
  position: absolute;
  right: 0px;
  top: -30px;
  line-height: 30px;
}
.header-navbar .nav-pages  > li {
  float: left;
  font-size: 12px;
}
.header-navbar .nav-pages  > li  > a {
  font-family: 'Droid Sans', sans-serif;
  padding: 0px 6px;
}
.header-navbar .nav-pages  > li  > a,
.header-navbar .nav-pages  > li.active > a {
  background-color: transparent;
}
.header-navbar .nav-icons {
  position: relative;
  height: 44px;
  width: 44px;
  float: right;
  margin-right: -10px;
}
.header-navbar .nav-icons  > li {
  width: 44px;
  height: 44px;
}
.header-navbar .nav-icons  > li  > a {
  padding: 0px;
  line-height: 44px;
  width: 44px;
  text-align: center;
}
.header-navbar .nav-icons .search-toggled-out-icon {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.header-navbar .nav-icons .search-toggled-in-icon {
  display: none;
}
/**************************************************************************
 * 4. Header Navbar
 */
.nav.navbar-nav {
  height: 44px;
  line-height: 44px;
  float: none;
  display: block;
  width: auto;
  font-size: 13px;
}
.nav.navbar-nav  > li {
  height: 44px;
  line-height: 44px;
  color:#FFF;
}
.nav.navbar-nav  > li.nav-all {
  border-left: transparent 1px solid;
}
.nav.navbar-nav  > li.nav-all .text {
  display: none;
  font-size: 12px;
  color:#FFF;
}
.nav.navbar-nav  > li.nav-all  > a {
  color:#FFF;
  padding: 0px 15px;
  line-height: 44px;
  height: 44px;
}
.nav.navbar-nav  > li  > a {
  color:#FFF;
  padding: 0px 10px;
  line-height: 44px;
  height: 44px;
  text-transform: uppercase;
}
.nav.navbar-nav  > li  > a:hover {
  background-color: transparent;
}
.nav.navbar-nav li .subnav-wrapper {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 10;
  padding: 0px 0px;
  line-height: 18px;
  border-top: 4px solid transparent;
  font-family: 'Droid Sans', sans-serif;
}
.nav.navbar-nav li .subnav-wrapper .subnav-wrapper {
  position: absolute;
  left: 100%;
  top: -4px;
}
.nav.navbar-nav li.full-subnav-wrapper {
  position: static;
}
.nav.navbar-nav li.full-subnav-wrapper .subnav-wrapper {
  left: 0px;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}
.nav.navbar-nav li.full-subnav-wrapper .subnav-wrapper .subnav,
.nav.navbar-nav li.full-subnav-wrapper .subnav-wrapper .subnav-entries {
  min-width: inherit;
  width: 100%;
}
.nav.navbar-nav li ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.nav.navbar-nav li ul li a {
  display: block;
}
.nav.navbar-nav li ul li a:hover {
  text-decoration: none;
}
.nav.navbar-nav li.toggled-in  > .subnav-wrapper,
.no-touch .nav.navbar-nav li:hover  > .subnav-wrapper {
  display: block;
}
.nav.navbar-nav li .subnav {
  min-width: 150px;
}
.nav.navbar-nav li .subnav li {
  position: relative;
}
.nav.navbar-nav li .subnav li a {
  padding: 10px 10px;
}
.nav.navbar-nav li .subnav-entries {
  width: 230px;
}
.nav.navbar-nav li .subnav-entries li {
  font-size: 12px;
  padding: 0px;
}
.nav.navbar-nav li .subnav-entries li a {
  display: block;
  padding: 8px 10px;
}
.nav.navbar-nav li .subnav-full {
  margin-bottom: 20px;
}
.nav.navbar-nav li .subnav-full a {
  padding: 8px 8px;
  margin-left: -8px;
}
.nav.navbar-nav li .subnav-tabbed .subnav-tabbed-tabs {
  padding-top: 20px;
  height: 100%;
}
.nav.navbar-nav li .subnav-tabbed .subnav-tabbed-tabs a {
  padding: 8px 10px 4px 15px;
}
.nav.navbar-nav li .subnav-tabbed .subnav-tabbed-panels {
  padding-top: 20px;
}
.nav.navbar-nav li .subnav-tabbed .subnav-tabbed-panels .subnav-tabbed-panel {
  display: none;
}
.nav.navbar-nav li .subnav-tabbed .subnav-tabbed-panels .subnav-tabbed-panel.active {
  display: block;
}
.subnav-header {
  text-transform: uppercase;
  display: block;
  padding-bottom: 10px;
  margin-bottom: 5px;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
}
/**************************************************************************
 * 5. Main
 */
#main {
  padding-top: 0px;
  position: relative;
}
.page-header {
  border: none;
  margin-top: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}
.page-header .page-meta.pull-right {
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
}
.page-header[class*="col-"] .page-title,
.page-header[class^="col-"] .page-title {
  margin-top: 0px;
  position: relative;
  left: -15px;
}
.page-title,
.page-title.full-page-title {
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 5px;
  font-weight: 300;
  overflow: hidden;
}
/**************************************************************************
 * 6. Section
 */
.section {
  position: relative;
  margin-bottom: 20px;
  *zoom: 1;
}
.section:before,
.section:after {
  display: table;
  line-height: 0;
  content: "";
}
.section:after {
  clear: both;
}
.section .section-title {
  top: 0px;
  font-size: 18px;
  line-height: 38px;
  font-weight: 300;
}
.section.section-no-margin-bottom {
  margin-bottom: 0px;
}
.container > .section:not(.row),
.container > .section.not-row {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: -15px;
  margin-right: -15px;
}
.container > .section.carousel {
  padding-left: 0px;
  padding-right: 0px;
}
/**************************************************************************
 * 7. Widget
 */
.widget {
  margin-bottom: 20px;
  position: relative;
}
.bottombar .widget {
  padding-top: 0px;
}
.widget .widget-title.ribbon {
  font-size: 14px;
  line-height: 38px;
  font-weight: 300;
}
.widget .widget-title:not(.ribbon) {
  margin-bottom: 15px;
}
.bottombar .widget .widget-title {
  font-size: 18px;
  line-height: 38px;
}
.widget .more-wrapper {
  margin-bottom: 0px;
}
.widget  > ul:not(.entries) {
  padding: 20px 0 0 0;
  list-style-type: none;
  margin: 0px;
}
.widget  > ul:not(.entries) li {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 20px;
}
.js .collapsible-widgets .widget  > * {
  display: none;
}
.js .collapsible-widgets .widget  > .widget-title {
  display: block;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  filter: alpha(opacity=90);
  cursor: pointer;
}
.js .collapsible-widgets .widget  > .widget-title:hover {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.js .collapsible-widgets .widget.active  > * {
  display: block;
}
.js .collapsible-widgets .widget.active  > .widget-title {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
/**************************************************************************
 * 8. Footer
 */
.footer-area {
  padding-top: 25px;
  font-size: 13px;
}
.footer-area a:hover {
  text-decoration: none;
}
.footer-area .widget .widget-title {
  font-size: 13px;
  text-transform: uppercase;
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
}
.footer-area .footer-top {
  margin-bottom: 20px;
}
.footer-area .footer-bottom {
  border-top: 1px solid transparent;
  font-size: 12px;
  line-height: 18px;
  padding-top: 10px;
}
.footer-area .footer-bottom p {
  margin-bottom: 10px;
}
/**************************************************************************
 * 9. Responsive
 */
@media (min-width: 768px) {
  .sm-pull-right {
    float: right;
  }
  .page-header {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .page-title,
  .page-title.full-page-title {
    font-size: 41px;
    line-height: 48px;
  }
}
@media (max-width: 768px) {
  .header-navbar,
  body.scrolled .header-navbar {
    position: absolute;
  }
  .header-navbar {
    margin-top: 0px;
  }
  .header-navbar .navbar-brand {
    width: 192px;
  }
  .header-navbar .navbar-brand a {
    top: 0px;
    left: -5px;
    width: 192px;
    height: 44px;
  }
  .header-navbar .navbar-brand a:after {
    top: 44px;
    bordsuber-bottom-width: 10px;
    border-right-width: 10px;
    display: none;
  }
  .header-navbar .nav.navbar-nav {
    margin: 0px;
  }
  .header-navbar .nav.navbar-nav  > li {
    display: none;
  }
  .header-navbar .nav.navbar-nav  > li:first-child {
    display: block;
  }
  .header-navbar .header-navbar-inner:after {
    display: none;
  }
  #main {
    padding-top: 44px;
  }
}
@media (min-width: 400px) {
    @media (max-width: 768px) {
              .entry.style-grid.type-post .entry-header{
                padding-left:20px;
              }
              .page-title {
                font-size: 20px;
                line-height: 25px;
                padding-bottom: 10px;
              }
              #main {
                padding-top: 0px;
              }
              .navbar-header{
                text-align: center;
              }
              .nav.navbar-nav > li, .nav.navbar-nav > li a{
                float: none;
                text-align: center;
              }
              .navbar-toggle{
                float: none;
                color: #FFF;
              }
              .header-row .col-md-3{
                text-align: center;
              }
              .header-row .col-md-9{
                display: none;
              }
              .job-list ul li{
                width:100%;
                list-style: none;
              }

              h2 {
                font-size: 20px;
                line-height: 23px;
                
              }
              h2.entry-title{
                padding-bottom: 0px;
                margin-bottom: 0px;
              }
            }
}
@media (min-width: 769px) {
  .header-row .col-md-3{
                text-align: center;
              }
  .page-title {
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 10px;
  }
  .nav.navbar-nav > li {
    display: none;
  }
  .nav.navbar-nav > li:nth-child(1),
  .nav.navbar-nav > li:nth-child(2),
  .nav.navbar-nav > li:nth-child(3) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child,
  .lt-ie9 .nav.nav.navbar-nav > li:first-child + li,
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li {
    display: block;
  }
}
@media (max-width: 790px) {
  .header-navbar .nav-icons,
  .header-navbar .nav.navbar-nav > li.nav-all {
    margin-right: 0px;
  }
  .header-navbar:after {
    display: none;
  }
}
@media (min-width: 899px) {
  .nav.navbar-nav > li:nth-child(4) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li {
    display: block;
  }
}
@media (max-width: 992px) {
  .page-header[class*="col-"] .page-title,
  .page-header[class^="col-"] .page-title {
    left: auto;
  }
}
@media (min-width: 992px) {
  .colheight-sm-1 {
    height: 175px;
  }
  .colheight-sm-2 {
    height: 350px;
  }
  .colheight-sm-3 {
    height: 525px;
  }
  .colheight-md-1 {
    height: 175px;
  }
  .colheight-md-2 {
    height: 350px;
  }
  .colheight-md-3 {
    height: 525px;
  }
  .md-pull-right {
    float: right;
  }
  .nav.navbar-nav > li.nav-all .text {
    display: block;
  }
  .nav.navbar-nav > li.nav-all .toggle {
    display: none;
  }
  .nav.navbar-nav > li:nth-child(5) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li + li {
    display: block;
  }
  .page-header {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
@media (min-width: 1150px) {
  .nav.navbar-nav > li:nth-child(6) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li + li + li {
    display: block;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .colheight-sm-1,
  .colheight-md-1 {
    height: 200px;
  }
  .colheight-sm-2,
  .colheight-md-2 {
    height: 400px;
  }
  .colheight-sm-3,
  .colheight-md-3 {
    height: 600px;
  }
  .colheight-lg-1 {
    height: 200px;
  }
  .colheight-lg-2 {
    height: 400px;
  }
  .colheight-lg-3 {
    height: 600px;
  }
  .lg-pull-right {
    float: right;
  }
  .content .md-pull-right .entry-thumbnail {
    margin-left: -16px;
  }
  .page-title {
    font-size: 30px;
    line-height: 35px;
    padding-bottom: 10px;
  }
  .page-title.full-page-title {
    font-size: 50px;
    line-height: 57px;
  }
}
@media (min-width: 1300px) {
  .container {
    max-width: inherit;
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (min-width: 1540px) {
  .container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
  }
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11 {
    float: left;
  }
  .col-xl-1 {
    width: 8.333333333333332%;
  }
  .col-xl-2 {
    width: 16.666666666666664%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33333333333333%;
  }
  .col-xl-5 {
    width: 41.66666666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.333333333333336%;
  }
  .col-xl-8 {
    width: 66.66666666666666%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33333333333334%;
  }
  .col-xl-11 {
    width: 91.66666666666666%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-push-1 {
    left: 8.333333333333332%;
  }
  .col-xl-push-2 {
    left: 16.666666666666664%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.33333333333333%;
  }
  .col-xl-push-5 {
    left: 41.66666666666667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.333333333333336%;
  }
  .col-xl-push-8 {
    left: 66.66666666666666%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.33333333333334%;
  }
  .col-xl-push-11 {
    left: 91.66666666666666%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-pull-1 {
    right: 8.333333333333332%;
  }
  .col-xl-pull-2 {
    right: 16.666666666666664%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.33333333333333%;
  }
  .col-xl-pull-5 {
    right: 41.66666666666667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.333333333333336%;
  }
  .col-xl-pull-8 {
    right: 66.66666666666666%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.33333333333334%;
  }
  .col-xl-pull-11 {
    right: 91.66666666666666%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .col-xl-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-xl-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666666666666%;
  }
  .colheight-sm-1,
  .colheight-md-1,
  .colheight-lg-1 {
    height: 220px;
  }
  .colheight-sm-2,
  .colheight-md-2,
  .colheight-lg-2 {
    height: 440px;
  }
  .colheight-sm-3,
  .colheight-md-3,
  .colheight-lg-3 {
    height: 660px;
  }
  .colheight-xl-1 {
    height: 220px;
  }
  .colheight-xl-2 {
    height: 440px;
  }
  .colheight-xl-3 {
    height: 660px;
  }
  .xl-pull-right {
    float: right;
  }
}
@media (min-width: 1250px) {
  .nav.navbar-nav > li:nth-child(7) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li + li + li + li {
    display: block;
  }
}
@media (min-width: 1490px) {
  .nav.navbar-nav > li:nth-child(8) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li + li + li + li + li {
    display: block;
  }
}
@media (min-width: 1610px) {
  .nav.navbar-nav > li:nth-child(9) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li + li + li + li + li + li {
    display: block;
  }
}
@media (min-width: 1730px) {
  .nav.navbar-nav > li:nth-child(10) {
    display: block;
  }
  .lt-ie9 .nav.navbar-nav > li:first-child + li + li + li + li + li + li + li + li + li {
    display: block;
  }
}
